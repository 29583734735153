import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
import './utils/rem'
// 动态粒子背景
import VueParticles from 'vue-particles'  
Vue.use(VueParticles)

// 国际化 + element组件国际化
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(ElementUI);
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': Object.assign(require("./lang/zh.json"), zhLocale),
    'en': Object.assign(require("./lang/en.json"), enLocale)
}
})
locale.i18n((key, value) => i18n.t(key, value))
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
