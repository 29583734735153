<template>
  <div style="min-width: 1000px;" id="app">
    <j-header v-if="$route.meta.isHeader"></j-header>
    <vue-particles
      v-if="$route.meta.isHeader === false"
      color="#409EFF"
      :particleOpacity="0.7"
      :particlesNumber="60"
      shapeType="circle"
      :particleSize="6"
      linesColor="#409EFF"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      class="login-bg"
    >
    </vue-particles>
    <router-view/>
  </div>
</template>
<script>
export default {
  components: {
    jHeader: () => import('./components/header'),
  }
}
</script>
<style lang="scss">
#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
}

.login-bg{
   width: 100%;
    height: 100%;
    background: #3E3E3E;
}
</style>