import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Graphical',
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/graphical.vue'),
    meta: {
      isHeader: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/system/login.vue'),
    meta: {
      isHeader: false
    },
  },
  {
    path: '/regis',
    name: 'Regis',
    component: () => import(/* webpackChunkName: "about" */ '../views/system/regis.vue'),
    meta: {
      isHeader: false
    },
  },
  {
    path: '/forgetPass',
    name: 'ForgetPass',
    component: () => import(/* webpackChunkName: "about" */ '../views/system/forgetPass.vue'),
    meta: {
      isHeader: false
    },
  },
  {
    path: '/clause',
    name: 'Clause',
    component: () => import(/* webpackChunkName: "about" */ '../views/system/clause.vue'),
    meta: {
      isHeader: false
    },
  },
  {
    path: '/list',
    name: 'List',
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/list.vue'),
    meta: {
      isHeader: true
    },
  },
  {
    path: '/description',
    name: 'Description',
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/description.vue'),
    meta: {
      isHeader: true
    },
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/tab/detail.vue'),
    meta: {
      isHeader: true
    },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/index.vue'),
    redirect: {path: 'userinfo'},
    children: [
      {
        path: '/userinfo',
        name: 'Userinfo',
        component: () =>import(/* webpackChunkName: "about" */ '../views/user/userinfo.vue'),
        meta: {
          isHeader: true
        },
      },
      {
        path: '/record',
        name: 'Record',
        component: () =>import(/* webpackChunkName: "about" */ '../views/user/record.vue'),
        meta: {
          isHeader: true
        },
      },
      {
        path: '/setpass',
        name: 'Setpass',
        component: () =>import(/* webpackChunkName: "about" */ '../views/user/setpass.vue'),
        meta: {
          isHeader: true
        },
      },
      {
        path: '/collection',
        name: 'Collection',
        component: () =>import(/* webpackChunkName: "about" */ '../views/user/collection.vue'),
        meta: {
          isHeader: true
        },
      },
      {
        path: '/updataList',
        name: 'UpdataList',
        component: () =>import(/* webpackChunkName: "about" */ '../views/user/updataList.vue'),
        meta: {
          isHeader: true
        },
      }
    ]
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/demo/index.vue'),
    meta: {
      isHeader: true
    },
  },   
  {
    path: '*',
    hidden: true,
    name: 'other',
    redirect: { path: '/' }
  },
]

const router = new VueRouter({
  routes
})

export default router
